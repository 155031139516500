import * as React from 'react'
import styled from 'styled-components'
import CheckIcon from 'svgs/check.svg'
import { Card } from 'components/styled/card'
// COMPONENTS
// import { ShadowText } from 'components/styled/typography'
import Layout from 'layouts/main'
import { SCREEN } from 'styles/screens'
import { navigate } from 'gatsby-link'

import Fb from 'svgs/facebook.svg'
import CopyIcon from 'svgs/newDesigns/copyIcon.svg'
import CopyIconDark from 'svgs/newDesigns/copyIconDark.svg'
import LinkedIn from 'svgs/linkedin.svg'
import Twitter from 'svgs/twitter.svg'
import { config } from '../config'

import { Button } from 'components/styled/button'
import ArrowIcon from 'svgs/arrow_left.svg'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Slider from 'react-slick'
// import { Card } from 'components/styled/card'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 100px 40px 100px;
  // min-height: 800px;
  margin-bottom: 60px;
  .product-video {
    max-width: 100%;
    border-radius: 20px;
  }

  img {
    max-width: 100%;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding: 16px;
    min-height: 600px;
  }
  .product-video {
    margin-bottom: 100px;
  }

  .column-title {
    font-weight: 500;
    margin-bottom: 0;
  }
`

const FixedContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1520px;
`

const DescriptionContainer = styled.div`
  width: 100%;

  .header {
    padding: 42px 0;
    border-radius: 0;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #b7b7b7;

    .info {
      width: 100%;
      margin-left: 40px;

      .title,
      .text {
        line-height: 1;
        margin-top: 0;
        margin-bottom: 0;
      }

      .title {
        font-size: 50px;
        color: #000;
      }

      .text {
        font-weight: 500;
        font-size: 26px;
        // color: #676767;
        margin-top: 10px;
        margin-bottom: 25px;
      }

      .primary-badge {
        margin-right: 20px;
        color: #000;
        font-size: 14px;
        margin-top: 10px;
        padding: 6px 20px;
        font-weight: 500;
        background-color: var(--secondary);
        border-radius: 10px;

        + .time {
          margin-top: 10px;
        }
      }
    }

    .time {
      margin-right: 20px;
      // margin-left: auto;
      color: #676767;
      font-size: 18px;
    }

    .product-link {
      color: #000;
      font-size: 14px;

      svg {
        margin-right: 10px;
      }

      font-weight: 500;
      margin-left: auto;
      text-decoration: none;
    }

    .social {
      margin-top: 20px;

      svg {
        margin-right: 20px;

        path {
          fill: #000;
        }
      }
    }

    .mobile-time {
      .time {
        font-size: 14px;
        margin-left: 0;
        margin-right: 4px;
      }

      .primary-badge {
        font-size: 13px;
        padding: 5px;
      }

      display: none;
    }

    .product-link-mobile {
      display: none;
    }

    .avatar img {
      object-fit: cover;
      width: 150px;
      height: 150px;
    }

    @media only screen and (max-width: ${SCREEN.MOBILE}) {
      .info {
        margin-left: 20px;
      }

      .product-link {
        display: none;
      }

      .product-link-mobile {
        margin-top: 8px;
        color: #0656f1;
        font-size: 14px;
        text-decoration: none;
        font-weight: 500;

        svg {
          margin-right: 8px;
        }

        display: block;
      }

      .avatar img {
        width: 60px;
        height: 60px;
      }

      .text {
        font-size: 16px !important;
      }

      .title {
        font-size: 20px !important;
      }

      .time {
        font-size: 14px !important;
      }

      .text + .d-flex {
        align-items: center;
      }

      // .mobile-time {
      //   display: flex;
      //   margin-top: 10px;
      //   align-items: center;
      //   .time {
      //     margin-right: 10px;
      //     display: block;
      //   }
      //   .primary-badge {
      //     display: block;
      //   }
      // }
      // .time {
      //   display: none;
      // }
      // .primary-badge {
      //   display: none;
      // }
    }
  }
`

const SliderElement = styled(Card)`
  position: relative;
  padding: 0;
  height: 100%;
  max-width: 100%;
  background: transparent;
  // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: none;
  // border-radius: 10px;
  // overflow: hidden;
  // img {
  //   height: auto;
  // }
`

const StyledCard = styled(Card)`
  &:last-child {
    margin-left: auto;
  }

  &:first-child {
    > ul {
      list-style-type: decimal !important;

      li {
        display: list-item !important;
        text-align: -webkit-match-parent;
      }
    }
  }

  position: relative;
  padding: 40px 48px 18px 48px;
  flex: 1 0 48%;
  max-width: 48%;
  align-self: stretch;
  margin-top: 20px;
  box-shadow: none;
  border: 1px solid #9b9b9b;

  > h6 {
    font-weight: 500;
  }

  h5 {
    font-weight: bold;
  }

  > ul li {
    font-size: 23px;
    font-weight: 500;
    display: flex;
    align-items: baseline;

    > div {
      margin-right: 1rem;
    }
  }

  ul {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    flex: 0 0 100%;
    max-width: 100%;
    h5 {
      font-size: 24px;
    }

    ul li {
      font-size: 16px;

      svg {
        width: 15px;
      }

      > .d-flex {
        margin-right: 10px;
      }
    }
  }
`

const ColumnSix = styled.div`
  &:last-child {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  max-width: 50% !important;
  flex: 0 0 50%;
  padding-left: 15px;
  padding-right: 15px;

  .text {
    font-size: 23px;
  }

  h3 {
    width: 100%;
  }
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    h3 {
      font-size: 24px;
    }

    .text {
      font-size: 16px;
    }

    flex: 0 0 100% !important;
    max-width: 100% !important;
    // min-height: 800px;
  }
`

const Row = styled.div`
  margin-top: 100px;
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
`

const StyledButton = styled(Button)`
  margin-top: calc(var(--spacing) * 6);
  width: auto;
  background-color: transparent;
  border: none;
  color: #696969;

  svg {
    margin-right: 10px;
  }

  &:hover {
    text-decoration: underline;
  }
`

const ProductName = ({ pageContext }) => {
  const { product } = pageContext

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          dots: true,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <Layout title={product.name}>
      <Container>
        <FixedContainer>
          <DescriptionContainer>
            <StyledButton
              onClick={() => {
                navigate(`/portfolio`)
              }}
            >
              <ArrowIcon />
              Back
            </StyledButton>

            <div className="header">
              <div className="avatar">
                <img
                  src={`${config.CDN_BASE_URL}/${product.logo}`}
                  alt="workspace"
                  layout="constrained"
                  placeholder="tracedSVG"
                />
              </div>
              <div className="info">
                <h6 className="title">{product.name}</h6>
                <a href={product.website} className="product-link-mobile">
                  <CopyIcon /> {product.website}
                </a>
                <div className="mobile-time">
                  <div className="time">{product.batch}</div>
                  {product.tags.split(',').map(tag => {
                    return <span className="primary-badge">{tag}</span>
                  })}
                </div>
                <p className="text">{product.slogan}</p>
                <div className="d-flex flex-wrap align-items-center">
                  {product.tags.split(',').map(tag => {
                    return <span className="primary-badge">{tag}</span>
                  })}
                  <div className="time">{product.batch}</div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="social">
                    {product.facebook && (
                      <a href={product.facebook} target={'_blank'}>
                        <Fb />
                      </a>
                    )}
                    {product.linkedin && (
                      <a href={product.linkedin} target={'_blank'}>
                        <LinkedIn />
                      </a>
                    )}
                    {product.twitter && (
                      <a href={product.twitter} target={'_blank'}>
                        <Twitter />
                      </a>
                    )}
                  </div>
                  <a href={product.website} className="product-link">
                    <CopyIconDark />
                    {product.website}
                  </a>
                </div>
              </div>
            </div>
            <Row>
              <ColumnSix>
                <h3>Challenge</h3>
                <p className="text">{product.challenge}</p>
                <h3>Description</h3>
                <p className="text">{product.description}</p>
              </ColumnSix>

              {product.screenshots && (
                <ColumnSix>
                  {product.video ? (
                    <iframe
                      width="560"
                      height="315"
                      src={`${product.video}`}
                      title="YouTube video player"
                      frameborder="0"
                      className="product-video"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  ) : (
                    ''
                  )}

                  <div className="w-100 product">
                    <Slider {...settings} className="test">
                      {product.screenshots.map(screenshot => {
                        return (
                          <SliderElement>
                            <div className="position-relative">
                              <img
                                src={`${config.CDN_BASE_URL}/${screenshot}`}
                                alt="profile-picture"
                                layout="fullWidth"
                                width="100%"
                                placeholder="tracedSVG"
                              />
                              {/* <div className="badge">Topic {topic.topicNumber}</div> */}
                            </div>
                          </SliderElement>
                        )
                      })}
                    </Slider>
                  </div>
                </ColumnSix>
              )}
            </Row>
            <Row>
              <StyledCard>
                <h5 className="mt-0 ml-46px">Key Features</h5>
                <ul className="mt-0">
                  {product.features.split('\n').map(feature => {
                    return <li className="mt-5px">{feature}</li>
                  })}
                </ul>
              </StyledCard>
              <StyledCard>
                <h5 className="mt-0 ml-46px">Key Benefits</h5>
                <ul className="list-unstyled mt-0">
                  {product.benefits.split('\n').map(benefit => {
                    return (
                      <li className="mt-5px">
                        <div className="d-flex">
                          <CheckIcon />
                        </div>
                        {benefit}
                      </li>
                    )
                  })}
                </ul>
              </StyledCard>
            </Row>
          </DescriptionContainer>
        </FixedContainer>
      </Container>
    </Layout>
  )
}

export default ProductName
